import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SliderItem from "./SliderItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../helper";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "104px"
    }
  },
  slidersWrapper: {
    height: "100vh",
    marginBottom: "-60px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: "calc(100vh - 104px)",
      flexDirection: "column",
      marginBottom: "40px"
    }
  }
}));

export default function SpacingGrid() {
  const [activeId, setActiveId] = React.useState(1);
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid
          container
          justify="center"
          spacing={0}
          className={classes.slidersWrapper}
        >
          <SliderItem
            onClick={() => setActiveId(1)}
            isActive={activeId == 1}
            title={
              <>
                World-class
                <br /> event transportation
              </>
            }
            subtitle={
              <>
                We simplify the most complex transport, logistics and events
                projects, using bespoke technology to reduce costs and
                streamline operations
              </>
            }
            image={
              <StaticImage
                src="../../assets/images/home/HOME PAGE - EVENT TRANSPORT_1.jpg"
                alt="world class event transportation"
                draggable="false"
                loading="eager"
              />
            }
            buttonLabel={"Read more"}
            buttonLink={"/event-transportation-service/"}
          />
          <SliderItem
            onClick={() => setActiveId(2)}
            isActive={activeId == 2}
            title={
              <>
                Corporate
                <br /> transportation
              </>
            }
            subtitle={
              <>
                Shuttle service, Staff transport, or Fleet procurement, every
                solution is tailored uniquely to your needs and wants.
              </>
            }
            image={
              <StaticImage
                src="../../assets/images/home/HOME PAGE - CORPORATE TRANSPORT_2.jpg"
                alt="corporate transportation"
                draggable="false"
                loading="eager"
              />
            }
            buttonLabel={"Read more"}
            buttonLink={"/transportation-management-service/"}
          />
          <SliderItem
            onClick={() => setActiveId(3)}
            isActive={activeId == 3}
            title={
              <>
                Transportation <br />
                logistics
              </>
            }
            subtitle={
              <>
                Our teams simplify the most complex movements of people, plant
                materials. We can partner strategically to plan entire systems.
              </>
            }
            image={
              <StaticImage
                src="../../assets/images/home/transportation-logistics.jpeg"
                alt="transportation logistics"
                draggable="false"
                loading="eager"
              />
            }
            buttonLabel={"Read more"}
            buttonLink={"/event-logistics-service/"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
