import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import { mobileViewBreakpoint, largeDesktopViewBreakpoint } from "../helper";
import Link from "gatsby-link";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  slideContent: {
    bottom: 0,
    position: "absolute",
    paddingLeft: 48,
    paddingBottom: 64,
    left: 0,
    width: "90%",
    "& > h1, > h2, > h4": {
      color: "#fff",
      marginBottom: 16,
      transition: "all ease-in-out 0.6s"
    },
    "& > h2": {
      whiteSpace: "nowrap",
      fontWeight: "bold",
      [theme.breakpoints.up(largeDesktopViewBreakpoint)]: {
        fontSize: "32px"
      }
    },
    "&.contentActive": {
      "& > h2": {
        position: "relative",
        animation: `$sliderTitleAnimation 300ms ${theme.transitions.easing.easeInOut} both`
      },
      [theme.breakpoints.down("md")]: {
        "& > h2": {
          position: "relative",
          animation: `$sliderTitleAnimationMobile 300ms ${theme.transitions.easing.easeInOut} both`
        }
      }
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 32
    },

    "& > h4": {
      marginBottom: 8
    },

    "& button": {
      height: 40
    }
  },
  subTitleSectionContainer: {
    opacity: "0",
    visibility: "hidden",
    height: "0px",

    transition: "all ease-in-out 0.6s",

    "&.secActive": {
      opacity: "1",
      visibility: "visible",
      height: "auto",
      animation: `$sliderInfoAnimation 300ms ${theme.transitions.easing.easeInOut} both`,
      [theme.breakpoints.down("md")]: {
        animation: `$sliderInfoAnimation 300ms ${theme.transitions.easing.easeInOut} both`
      }
    }
  },

  banneImage: {
    position: "absolute",
    height: "100%",
    width: "100%",
    "& .gatsby-image-wrapper": {
      width: "100%",
      height: "100%"
    },
    [theme.breakpoints.down("md")]: {
      overflow: "hidden"
    }
  },

  subTitleSection: {
    marginBottom: "24",
    transition: "all ease 1s",

    "& h4, p": {
      color: "#fff",
      marginBottom: 24,
      maxWidth: 578,

      [theme.breakpoints.down("md")]: {
        marginBottom: 16
      },
      [theme.breakpoints.up(largeDesktopViewBreakpoint)]: {
        fontSize: "24px"
      }
    },

    [theme.breakpoints.down("md")]: {
      marginBottom: 16
    }
  },

  hoverItem: {
    transition: "all cubic-bezier(0.25, 0.1, 0.01, 1.06) 0.6s",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100vh",
    cursor: "pointer",
    flex: 1,
    "&.isActive": {
      flex: 2,
      cursor: "default"
    },
    [theme.breakpoints.down("md")]: {
      height: "25%",

      "& h5": {
        color: "#fff",
        marginBottom: 0
      },

      "&.isActive": {
        height: "50%",

        "& .gatsby-image-wrapper": {
          width: "100%",
          height: "100%"
        }
      }
    }
  },
  "@keyframes sliderTitleAnimation": {
    "0%": {
      opacity: 0,
      top: 10,
      left: 20
    },
    "100%": {
      opacity: 1,
      left: 0,
      top: 0,
      fontSize: "60px"
    }
  },
  "@keyframes sliderTitleAnimationMobile": {
    "0%": {
      opacity: 0,
      top: 10,
      left: 20
    },
    "100%": {
      opacity: 1,
      left: 0,
      top: 0,
      fontSize: "24px"
    }
  },
  "@keyframes sliderInfoAnimation": {
    "0%": {
      opacity: 0,
      fontSize: 0
    },
    "100%": {
      opacity: 1,
      fontSize: "24px"
    }
  },
  "@keyframes sliderInfoAnimationMobile": {
    "0%": {
      opacity: 0,
      fontSize: 0
    },
    "100%": {
      opacity: 1,
      fontSize: "14px"
    }
  }
}));

export default function SpacingGrid({
  isActive,
  onClick,
  title,
  subtitle,
  image,
  buttonLabel,
  buttonLink
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      onClick={onClick}
      className={classNames([classes.hoverItem], { isActive: isActive })}
    >
      <div className={classes.banneImage}>{image}</div>
      <div
        className={
          isActive
            ? `${classes.slideContent}` + " contentActive"
            : `${classes.slideContent}`
        }
      >
        {(isMobileView && (
          <Typography
            component="h2"
            customVariant={isActive ? "h4Bold" : "h5Bold"}
          >
            {title}
          </Typography>
        )) || <h2>{title}</h2>}

        <div
          className={
            isActive
              ? `${classes.subTitleSectionContainer}` + " secActive"
              : `${classes.subTitleSectionContainer}`
          }
        >
          <div className={classes.subTitleSection}>
            <Typography component="p" customVariant={"bodySmallRegular"}>
              {subtitle}
            </Typography>
          </div>

          <a href={buttonLink}>
            <Button>{buttonLabel}</Button>
          </a>
        </div>
      </div>
    </div>
  );
}
